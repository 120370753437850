"use client"

import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronLeft, ChevronRight, Star } from "lucide-react"

const testimonials = [
  {
    name: "Marhaba club ",
    role: "Hôtel a Sousse",
    image: require("../media/narhaba club.png"),
    quote:
      "We had the pleasure of working with Dashiki Design for the installation of all the countertops at Marhaba Club Hotel in Sousse. From start to finish, the team displayed incredible professionalism and attention to detail. The quality of their work, using premium materials like inox and corian, has truly enhanced the aesthetic and functionality of our hotel. Our guests constantly compliment the sleek, modern design of the countertops, and we couldn't be happier with the end result. Dashiki Design is the trusted partner for any future projects!",
    rating: 5,
  },
  {
    name: "Maison Blanca",
    role: "traiteur a france ",
    image: require("../media/maison.png"),
    quote:
      "Nous avons choisi Dashiki Design pour la réalisation des comptoirs de notre espace de traiteur à Lyon, et nous sommes absolument ravis du résultat. Leur équipe a su comprendre nos besoins spécifiques et a créé des comptoirs en inox et corian qui allient à la perfection fonctionnalité et esthétique. Le design moderne et épuré a non seulement amélioré l'ambiance de notre boutique, mais a aussi impressionné nos clients. Nous recommandons vivement Dashiki Design pour leur professionnalisme et leur savoir-faire exceptionnel ",
    rating: 5,
  },
  {
    name: "pâtisserie au petit moulin ",
    role: "pâtisserie a france",
    image: require("../media/petit.jpeg"),
    quote:
      "Nous avons fait appel à Dashiki Design pour la création des comptoirs de notre pâtisserie, et nous ne pourrions pas être plus satisfaits. Leur expertise en matière de design et leur utilisation de matériaux comme l'inox et le corian ont donné un résultat à la fois élégant et pratique. Les comptoirs apportent une touche moderne tout en étant parfaitement adaptés à notre activité. Les retours de nos clients sont toujours positifs, et nous sommes fiers de travailler avec Dashiki Design. Une équipe professionnelle et à l'écoute de ses clients",
    rating: 5,
  },
  
]

export function TestimonialSection() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
    }, 8000)
    return () => clearInterval(timer)
  }, [])

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
  }

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length)
  }

  return (
    <section className="py-12 sm:py-24 bg-gradient-to-b from-zinc-900 to-black w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 sm:mb-12 text-center text-white"
        >
          Ce Que Disent <span className="text-[#007bff]">Nos Clients</span>
        </motion.h2>

        <div className="relative max-w-4xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
              className="bg-zinc-800 rounded-lg p-6 sm:p-8 shadow-xl"
            >
              <div className="flex flex-col items-center text-center">
                <img
                  src={testimonials[currentIndex].image || "/placeholder.svg"}
                  alt={testimonials[currentIndex].name}
                  className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-4 border-[#007bff] mb-4"
                />
                <p className="text-lg sm:text-xl md:text-2xl text-gray-300 italic mb-4 sm:mb-6">
                  "{testimonials[currentIndex].quote}"
                </p>
                <div className="flex items-center mb-2">
                  {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                    <Star key={i} className="w-4 h-4 sm:w-5 sm:h-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <h3 className="text-lg sm:text-xl font-semibold text-white">{testimonials[currentIndex].name}</h3>
                <p className="text-sm sm:text-base text-[#007bff]">{testimonials[currentIndex].role}</p>
              </div>
            </motion.div>
          </AnimatePresence>

          <button
            onClick={prevTestimonial}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-3 sm:-translate-x-6 md:-translate-x-12 bg-zinc-700 hover:bg-[#007bff] text-white p-1 sm:p-2 rounded-full transition-colors duration-300"
            aria-label="Previous testimonial"
          >
            <ChevronLeft className="w-4 h-4 sm:w-6 sm:h-6" />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-3 sm:translate-x-6 md:translate-x-12 bg-zinc-700 hover:bg-[#007bff] text-white p-1 sm:p-2 rounded-full transition-colors duration-300"
            aria-label="Next testimonial"
          >
            <ChevronRight className="w-4 h-4 sm:w-6 sm:h-6" />
          </button>
        </div>
      </div>
    </section>
  )
}

