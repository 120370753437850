"use client"
import { motion, AnimatePresence } from "framer-motion"
import { Building2, Mail, Phone, Facebook, Instagram, Linkedin } from "lucide-react"

const infoItems = [
  {
    icon: Building2,
    label: "Localisation",
    value: "Marsa, Tunis",
    href: "https://goo.gl/maps/yourLocation",
  },
  {
    icon: Mail,
    label: "Notre E-mail",
    value: "dashikicomptoirdesign@gmail.com",
    href: "mailto:dashikicomptoirdesign@gmail.com",
  },
  {
    icon: Phone,
    label: "Appelez-nous",
    value: "+216 27 781 212",
    href: "tel:+21627781212",
  },
]

const socialLinks = [
  { icon: Facebook, href: "https://www.facebook.com/dashikidesign" },
  { icon: Instagram, href: "https://www.instagram.com/dashiki.comptoir.design" },
  { icon: Linkedin, href: "https://www.linkedin.com/company/dashiki-comptoir-design" },
]

export function SubNavbar({ scrolled }) {
  return (
    <AnimatePresence>
      {!scrolled && (
        <motion.div
          className="bg-zinc-900  text-white fixed top-16 left-0 right-0 z-40"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="mx-auto max-w-7xl px-4">
            <div className="flex flex-col md:flex-row justify-between items-center py-2">
              {/* Contact Information */}
              <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8">
                {infoItems.map((item, index) => (
                  <motion.a
                    key={index}
                    href={item.href}
                    className="flex items-center gap-2 group hover:text-[#007bff] transition-colors"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="p-1.5 rounded-full bg-zinc-800 group-hover:bg-[#007bff]/10 transition-colors">
                      <item.icon className="h-4 w-4 text-[#007bff]" />
                    </div>
                    <div className="flex items-center gap-1">
                      <span className="text-xs font-semibold">{item.label}:</span>
                      <span className="text-xs text-zinc-400 group-hover:text-[#007bff] transition-colors">
                        {item.value}
                      </span>
                    </div>
                  </motion.a>
                ))}
              </div>

              {/* Social Links */}
              <div className="flex items-center gap-4 mt-2 md:mt-0">
                {socialLinks.map((link, index) => (
                  <motion.a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-1.5 rounded-full bg-zinc-800 hover:bg-[#007bff]/10 transition-colors group"
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <link.icon className="h-4 w-4 text-[#007bff] group-hover:text-[#007bff]" />
                  </motion.a>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

