"use client"


import { motion } from "framer-motion"
import { Trophy, Timer, Users, Star, } from "lucide-react"
import { useNavigate } from "react-router-dom"


const stats = [
  {
    icon: Trophy,
    value: "30+",
    label: "Ans d'expertise",
    description: "Dans la conception et fabrication",
  },
  {
    icon: Timer,
    value: "29+",
    label: "Projets réalisés",
    description: "Pour des clients satisfaits",
  },
  {
    icon: Users,
    value: "70%",
    label: "Clients fidèles",
    description: "Nous font confiance",
  },
  {
    icon: Star,
    value: "100%",
    label: "Satisfaction",
    description: "Garantie qualité",
  },
]


export function AboutSection() {
  const Navigate=useNavigate();

  return (
    <section id="about" className="py-24 bg-gradient-to-br from-white to-gray-100">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center gap-12 mb-20">
          {/* Left side: Video */}
          <motion.div
            className="lg:w-1/2"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="relative aspect-video rounded-lg overflow-hidden shadow-2xl">
              {/* Replace 'YOUR_VIDEO_SOURCE' with the actual video source */}
              <video className="w-full h-full object-cover" autoPlay controls loop muted  playsInline>
                <source src={require("../media/video2.mp4")} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </motion.div>

          {/* Right side: Content */}
          <motion.div
            className="lg:w-1/2"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <motion.span
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="inline-block text-[#007bff] font-medium mb-4 text-sm tracking-wider uppercase"
            >
              EN SAVOIR PLUS SUR NOUS
            </motion.span>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
            >
              La référence en conception de comptoirs
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
              className="text-gray-600 text-lg leading-relaxed mb-8"
            >
              Depuis 30 ans, notre usine façonne l'inox et le Corian® pour créer des comptoirs sur mesure, alliant
              esthétique et durabilité. Notre expertise et nos équipements de pointe garantissent des réalisations de
              qualité, adaptées à tous vos projets.
            </motion.p>

            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className="bg-[#007bff] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#0056b3] transition-colors duration-300 shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={()=>{
                Navigate("/projects")
              }}
            >
              Découvrir Nos Réalisations
            </motion.button>
          </motion.div>
        </div>

        {/* Stats */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-20">
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 + index * 0.1 }}
              className="relative group"
            >
              <div className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all duration-300 h-full border border-gray-100">
                <div className="flex flex-col items-center">
                  <div className="mb-4 p-3 bg-blue-50 rounded-xl group-hover:bg-blue-100 transition-colors duration-300">
                    <stat.icon className="w-8 h-8 text-[#007bff]" />
                  </div>
                  <div className="text-4xl font-bold text-gray-900 mb-2 group-hover:text-[#007bff] transition-colors duration-300">
                    {stat.value}
                  </div>
                  <div className="text-lg font-semibold text-gray-900 mb-1">{stat.label}</div>
                  <div className="text-gray-500 text-sm text-center">{stat.description}</div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        
      </div>
    </section>
  )
}

