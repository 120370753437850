"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { ChevronLeft, ChevronRight } from "lucide-react"

export default function ProjectsPage() {
  const [projects, setProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentProject, setCurrentProject] = useState(null)
  const [filter, setFilter] = useState("all")

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    if (filter === "all") {
      setFilteredProjects(projects)
    } else {
      setFilteredProjects(projects.filter((project) => project.type === filter))
    }
  }, [filter, projects])

  const fetchProjects = async () => {
    try {
      const response = await fetch("https://dashikidesign.com/newprojects.php")
      if (!response.ok) {
        throw new Error("Failed to fetch projects")
      }
      const data = await response.json()
      console.log("Fetched projects:", data) // Debug log
      setProjects(data)
      setFilteredProjects(data)
      setLoading(false)
    } catch (err) {
      console.error("Error fetching projects:", err) // Debug log
      setError("Failed to load projects. Please try again later.")
      setLoading(false)
    }
  }

  const openModal = (project) => {
    setCurrentProject(project)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const navigateModal = (direction) => {
    const currentIndex = filteredProjects.findIndex((p) => p.id === currentProject?.id)
    const newIndex = (currentIndex + direction + filteredProjects.length) % filteredProjects.length
    setCurrentProject(filteredProjects[newIndex])
  }

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section style={{marginTop:"100px"}} className="relative h-[90vh] py-12  bg-black">
        <video className="absolute inset-0 w-full h-full object-cover opacity-50" autoPlay loop muted playsInline>
          <source src={require("../media/video3.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Nos Réalisations</h1>
          <p className="text-xl md:text-2xl mb-8">Découvrez notre expertise en comptoirs sur mesure</p>
          <button
            onClick={() => document.getElementById("projects-grid")?.scrollIntoView({ behavior: "smooth" })}
            className="bg-[#007bff] text-white px-8 py-3 rounded-full font-semibold hover:bg-[#0056b3] transition-colors"
          >
            Découvrir Nos Comptoirs
          </button>
        </div>
      </section>

      {/* Filtering System */}
      <section className="py-8 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex justify-center space-x-4">
            {[
              { label: "Tout", value: "all" },
              { label: "Inox", value: "inox" },
              { label: "Corian", value: "corian" },
              { label: "Inox & Corian", value: "inox_et_corian" },
            ].map((category) => (
              <button
                key={category.value}
                onClick={() => setFilter(category.value)}
                className={`px-4 py-2 rounded-full transition-colors ${
                  filter === category.value
                    ? "bg-[#007bff] text-white"
                    : "bg-gray-200 hover:bg-[#007bff] hover:text-white"
                }`}
              >
                {category.label}
              </button>
            ))}
          </div>
        </div>
      </section>

      {/* Projects Grid */}
      <section id="projects-grid" className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProjects.map((project) => (
              <motion.div
                key={project.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="relative aspect-w-4 aspect-h-3">
                  <img
                    src={`https://dashikidesign.com${project.image}`}
                    alt={project.title}
                    className="object-cover w-full h-full"
                  />
                  <div
                    className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => openModal(project)}
                  >
                    <h3 className="text-white text-xl font-semibold">{project.title}</h3>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-2">{project.title}</h3>
                  <p className="text-sm text-gray-600">Type: {project.type}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal */}
      {modalOpen && currentProject && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-4">
            <img
              src={`https://dashikidesign.com${currentProject.image}`}
              alt={currentProject.title}
              className="w-full h-auto mb-4 rounded"
            />
            <h2 className="text-2xl font-bold mb-2">{currentProject.title}</h2>
            <p className="text-gray-600 mb-2">Size: {currentProject.size}</p>
            <p className="text-gray-600 mb-4">Type: {currentProject.type}</p>
            <div className="flex justify-between">
              <button onClick={() => navigateModal(-1)} className="bg-gray-200 hover:bg-gray-300 rounded-full p-2">
                <ChevronLeft className="w-6 h-6" />
              </button>
              <button onClick={closeModal} className="bg-[#007bff] text-white px-4 py-2 rounded hover:bg-[#0056b3]">
                Fermer
              </button>
              <button onClick={() => navigateModal(1)} className="bg-gray-200 hover:bg-gray-300 rounded-full p-2">
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

