"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import {
  ChevronLeft,
  ChevronRight,
  Pencil,
  PenToolIcon as Tools,
  Ruler,
  Truck,
  Settings,
  HeartHandshake,
} from "lucide-react"
import { useNavigate } from "react-router-dom"

const services = [
  {
    icon: Pencil,
    title: "Conception sur mesure",
    description:
      "Notre équipe de designers expérimentés travaille en étroite collaboration avec vous pour créer un comptoir unique.",
  },
  {
    icon: Tools,
    title: "Fabrication artisanale",
    description: "Nos artisans qualifiés utilisent des techniques de pointe et des matériaux de haute qualité.",
  },
  {
    icon: Ruler,
    title: "Prise de mesures",
    description: "Nous nous déplaçons chez vous pour prendre les mesures exactes de votre espace.",
  },
  {
    icon: Truck,
    title: "Installation & Livraison",
    description: "Nos équipes assurent une installation rapide, propre et efficace de votre comptoir.",
  },
  {
    icon: Settings,
    title: "Entretien",
    description: "Conseils experts pour l'entretien de votre comptoir en inox ou en Corian.",
  },
  {
    icon: HeartHandshake,
    title: "Service après-vente",
    description: "Nous restons à votre disposition pour toute question après l'installation.",
  },
]


export function ServicesSection() {
  const [activeSlide, setActiveSlide] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState(3)
  const totalSlides = Math.ceil(services.length / slidesPerView)
  const Navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSlidesPerView(1)
      } else if (window.innerWidth < 1024) {
        setSlidesPerView(2)
      } else {
        setSlidesPerView(3)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % totalSlides)
    }, 5000)
    return () => clearInterval(timer)
  }, [totalSlides])

  return (
    <section id="nos-services" className="py-12 sm:py-24 bg-black text-white">
      <div className="container mx-auto px-4">
        <div className="grid lg:grid-cols-2 gap-8 lg:gap-16 items-center">
          {/* Left Column - Content */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <span className="text-[#007bff] font-medium mb-2 sm:mb-4 block text-sm sm:text-base">NOS SERVICES</span>
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 sm:mb-6 leading-tight">
              Des services sur mesure pour vos comptoirs en inox et Corian
            </h2>
            <p className="text-gray-400 text-base sm:text-lg mb-6 sm:mb-8 leading-relaxed">
              Notre usine est spécialisée dans la conception, la fabrication et l'installation de comptoirs en inox et
              en Corian sur mesure. Nous proposons une gamme complète de services pour répondre à tous vos besoins,
              qu'ils soient résidentiels ou commerciaux.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={()=>{
                Navigate("/contact")
              }}
              className="bg-[#007bff] text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md hover:bg-[#0056b3] transition-colors text-sm sm:text-base"
            >
              Demandez un devis gratuit
            </motion.button>
          </motion.div>

          {/* Right Column - Service Slider */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="relative mt-8 lg:mt-0"
          >
            <div className="overflow-hidden">
              <motion.div animate={{ x: `-${activeSlide * 100}%` }} transition={{ duration: 0.5 }} className="flex">
                {Array.from({ length: totalSlides }).map((_, slideIndex) => (
                  <div key={slideIndex} className="grid grid-cols-1 gap-4 sm:gap-6 min-w-full">
                    {services
                      .slice(slideIndex * slidesPerView, slideIndex * slidesPerView + slidesPerView)
                      .map((service, index) => (
                        <motion.div
                          key={index}
                          whileHover={{ scale: 1.02 }}
                          className="bg-zinc-900 p-4 sm:p-6 rounded-xl hover:bg-zinc-800 transition-colors"
                        >
                          <service.icon className="w-6 h-6 sm:w-8 sm:h-8 text-[#007bff] mb-2 sm:mb-4" />
                          <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">{service.title}</h3>
                          <p className="text-gray-400 text-sm sm:text-base">{service.description}</p>
                        </motion.div>
                      ))}
                  </div>
                ))}
              </motion.div>
            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-center mt-4 sm:mt-6 gap-2">
              <button
                onClick={() => setActiveSlide((prev) => (prev - 1 + totalSlides) % totalSlides)}
                className="p-1 sm:p-2 rounded-full bg-zinc-800 hover:bg-zinc-700 transition-colors"
              >
                <ChevronLeft className="w-4 h-4 sm:w-6 sm:h-6" />
              </button>
              <button
                onClick={() => setActiveSlide((prev) => (prev + 1) % totalSlides)}
                className="p-1 sm:p-2 rounded-full bg-zinc-800 hover:bg-zinc-700 transition-colors"
              >
                <ChevronRight className="w-4 h-4 sm:w-6 sm:h-6" />
              </button>
            </div>

            {/* Slide Indicators */}
            <div className="flex justify-center mt-2 sm:mt-4 gap-1 sm:gap-2">
              {Array.from({ length: totalSlides }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setActiveSlide(index)}
                  className={`h-1 sm:h-2 rounded-full transition-all ${
                    activeSlide === index ? "w-6 sm:w-8 bg-[#007bff]" : "w-1 sm:w-2 bg-zinc-700"
                  }`}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

