import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ChevronDown, } from 'lucide-react';
import { SubNavbar } from './subnavbar';
import {useNavigate} from 'react-router-dom'

const navItems = [
  { href: "/", label: "Accueil" },
  { 
    href: "/#about", 
    label: "À Propos",
    
  },
  { 
    href: "/#nos-services", 
    label: "Nos Services",
    
  },
  { href: "/projects", label: "Portfolio" ,
   

  },
  { href: "/contact", label: "Contact" },
];

export function MainNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMouseEnter = (label) => {
    setActiveDropdown(label);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  return (
    <>
      <motion.nav
        className={`fixed top-0 left-0 right-0 z-50 bg-black border-b border-zinc-800 transition-all duration-300 ${
          scrolled ? 'py-2' : 'py-2'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <div className="mx-auto max-w-7xl px-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <motion.a
              href="/"
              className="flex items-center space-x-2 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="relative">
                <img 
                  src={require("../media/logo black.png")} 
                  style={{marginRight:"-3px"}}
                  alt="Dashiki Design Logo" 
                  className={`rounded-full mt-2 transition-all duration-300 ${
                    scrolled ? 'h-10 w-10' : 'h-12 w-12'
                  }`}
                />
                <motion.div
                  className="absolute inset-0 rounded-full bg-[#007bff]/20"
                  initial={{ scale: 0 }}
                  whileHover={{ scale: 1.4 }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <span className={`font-bold tracking-tight transition-all duration-300 ${
                scrolled ? 'text-xl' : 'text-2xl'
              }`}>
               <span  style={{ marginLeft:"-4px" }}  className="text-white transition-colors duration-300 group-hover:text-[#007bff]">ashiki</span>
                {" "}
                <span className="text-white transition-colors duration-300 group-hover:text-[#007bff]"><span  className="text-[#007bff] transition-colors duration-300   group-hover:text-white">C</span>omptoir</span>
              </span>
            </motion.a>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center space-x-8">
              {navItems.map((item) => (
                <div
                  key={item.href}
                  className="relative"
                  onMouseEnter={() => handleMouseEnter(item.label)}
                  onMouseLeave={handleMouseLeave}
                >
                  <motion.a
                    href={item.href}
                    className="flex items-center space-x-1 text-sm font-medium text-white transition-colors hover:text-[#007bff]"
                    whileHover={{ y: -2 }}
                  >
                    <span>{item.label}</span>
                    {item.subItems && (
                      <ChevronDown className="h-4 w-4 transition-transform duration-200" />
                    )}
                  </motion.a>

                  {/* Dropdown Menu */}
                  {item.subItems && activeDropdown === item.label && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute left-0 top-full mt-2 w-48 rounded-md bg-zinc-900 py-2 shadow-xl"
                    >
                      {item.subItems.map((subItem) => (
                        <motion.a
                          key={subItem.href}
                          href={subItem.href}
                          className="block px-4 py-2 text-sm text-gray-200 hover:bg-[#007bff] hover:text-white transition-colors"
                          whileHover={{ x: 4 }}
                        >
                          {subItem.label}
                        </motion.a>
                      ))}
                    </motion.div>
                  )}
                </div>
              ))}

              {/* Action Buttons */}
              <div className="flex items-center space-x-4">
                
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={()=>{
                    Navigate("/contact")
                  }}
                  className="bg-[#007bff] text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-[#0056b3] transition-colors"
                >
                  Devis Gratuit
                </motion.button>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <motion.button
              className="lg:hidden text-white p-2 hover:bg-zinc-800 rounded-md"
              onClick={() => setIsOpen(!isOpen)}
              whileTap={{ scale: 0.9 }}
            >
              {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </motion.button>
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="lg:hidden border-t border-zinc-800 mt-4"
              >
                <div className="py-4 space-y-4">
                  {navItems.map((item) => (
                    <div key={item.href} className="space-y-2">
                      <motion.a
                        href={item.href}
                        className="block px-4 py-2 text-sm text-white hover:bg-zinc-800 rounded-md transition-colors"
                        whileHover={{ x: 4 }}
                      >
                        {item.label}
                      </motion.a>
                      {item.subItems && (
                        <div className="pl-8 space-y-2">
                          {item.subItems.map((subItem) => (
                            <motion.a
                              key={subItem.href}
                              href={subItem.href}
                              className="block px-4 py-2 text-sm text-gray-400 hover:text-white hover:bg-zinc-800 rounded-md transition-colors"
                              whileHover={{ x: 4 }}
                            >
                              {subItem.label}
                            </motion.a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="px-4 pt-4 border-t border-zinc-800">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={()=>{
                        Navigate("/contact")
                      }}
                      className="w-full bg-[#007bff] text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-[#0056b3] transition-colors"
                    >
                      Devis Gratuit
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.nav>
      <SubNavbar scrolled={scrolled} />
    </>
  );
}
