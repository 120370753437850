"use client"
import { motion } from "framer-motion"
import { Facebook, Instagram, Linkedin, Mail, Phone, MapPin } from "lucide-react"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="bg-gradient-to-b from-zinc-900 to-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-4"
          >
            <div className="flex items-center space-x-2">
              {/* Logo placeholder */}
              <div className="w-20 h-20 md:text-left text-center rounded-full">
                <img src={require("../media/logo black.png")} className="rounded-full" alt="dashiki design comptoir logo"  />
              </div>
              <h3 className="text-2xl md:text-left text-center font-bold">ashiki Comptoir Design</h3>
            </div>
            <p className="text-sm md:text-left text-center text-gray-400">
              Spécialistes en conception et fabrication de comptoirs sur mesure en inox et Corian.
            </p>
          </motion.div>

          {/* Quick Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="space-y-4"
          >
            <h4 className="text-lg md:text-left text-center font-semibold">Liens Rapides</h4>
            <ul className="space-y-2">
              {[  "projects", "Contact"].map((item, index) => (
                <motion.li key={item} whileHover={{ x: 5 }} transition={{ type: "spring", stiffness: 300 }}>
                  <a
                    href={`/${item.toLowerCase()}`}
                    className="text-gray-400 md:text-left text-center hover:text-[#007bff] transition-colors duration-300"
                  >
                    {item}
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Contact Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-4"
          >
            <h4 className="text-lg md:text-left text-center font-semibold">Contact</h4>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <MapPin size={18} className="text-[#007bff]" />
                <span className="text-gray-400 md:text-left text-center ">Marsa, Tunis</span>
              </li>
              <li className="flex md:text-left text-center items-center space-x-2">
                <Phone size={18} className="text-[#007bff]" />
                <a
                  href="tel:+21627781212"
                  className="text-gray-400 md:text-left text-center hover:text-[#007bff] transition-colors duration-300"
                >
                  +216 27 781 212
                </a>
              </li>
              <li className="flex items-center md:text-left text-center  space-x-2">
                <Mail size={18} className="text-[#007bff]" />
                <a
                  href="mailto:dashikicomptoirdesign@gmail.com"
                  className="text-gray-400 md:text-left text-center hover:text-[#007bff] transition-colors duration-300"
                >
                  dashikicomptoirdesign@gmail.com
                </a>
              </li>
            </ul>
          </motion.div>

          {/* Social Media */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="space-y-4"
          >
            <h4 className="text-lg md:text-left text-center font-semibold">Suivez-nous</h4>
            <div className="flex space-x-4">
              {[
                { icon: Facebook, href: "https://www.facebook.com/dashikidesign" },
                { icon: Instagram, href: "https://www.instagram.com/dashiki.comptoir.design" },
                { icon: Linkedin, href: "https://www.linkedin.com/company/dashiki-comptoir-design" },
              ].map((social, index) => (
                <motion.a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ y: -5, scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="bg-zinc-800 p-2 md:text-left text-center rounded-full hover:bg-[#007bff] transition-colors duration-300"
                >
                  <social.icon size={20} />
                </motion.a>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Divider */}
        <motion.hr
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="my-8 border-zinc-800"
        />

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-sm text-gray-400"
          >
            © {currentYear} Dashiki Comptoir Design. Tous droits réservés.
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="text-sm text-gray-400 space-x-2"
          >
            <span>
              Powered by{" "}
              <a
                href="https://anweblab.amennoomen.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#007bff] hover:underline"
              >
                AN WebLab
              </a>
            </span>
            <span>|</span>
            <span>
              Developed by{" "}
              <a
                href="https://amennoomen.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#007bff] hover:underline"
              >
                Amen Allah Naamen
              </a>
            </span>
          </motion.div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

