import { AboutSection } from "./components/about-section"
import { FeaturesSection } from "./components/Features"
import Footer from "./components/footer"
import { HeroCarousel } from "./components/hero-section"
import { MainNavbar } from "./components/navbar"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ServicesSection } from "./components/services"
import { TestimonialSection } from "./components/Testimonial"

import Contact from "./pages/contact"
import ProjectsPage from "./pages/projects"


export default function App() {
  return (
    <Router>
    <div className="min-h-screen">
      <MainNavbar />
      <Routes>
        <Route path="/" element={
          <>
           <HeroCarousel/>
           <AboutSection/>
           <TestimonialSection/>
           <ServicesSection/>
           <FeaturesSection/>
           
           </>
        }/>
     <Route path="/contact" element={<Contact/>}/>
     <Route path="/projects" element={<ProjectsPage/>}/>
      </Routes>
      <Footer/>
    </div>
    </Router>
  )
}

