"use client"

import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronLeft, ChevronRight, Check } from "lucide-react"

import { useNavigate } from "react-router-dom"

const slides = [
  {
    title: "L'inox : l'élégance au service de la performance",
    subtitle: "l'inox",
    description:
      "Spécialisés dans la fabrication de comptoirs en inox sur mesure, nous vous proposons des solutions durables et esthétiques pour équiper vos cuisines professionnelles et privées.",
    features: [
      "Personnalisation : Dimensions, finitions, accessoires... nous créons le comptoir dont vous rêvez.",
      "Durabilité : Résistant aux chocs, aux rayures et à la corrosion.",
      "Hygiène : Surface non poreuse, facile à nettoyer et désinfecter.",
      "Esthétique : Un design moderne et intemporel.",
    ],
    image: require("../media/inoxcomptoir.jpg"),
  },
  {
    title: "Le Corian : la beauté rencontre la fonctionnalité",
    subtitle: "le Corian",
    description:
      "Découvrez nos comptoirs en Corian, un matériau innovant offrant une infinité de possibilités créatives. Souple, résistant et facile à façonner, le Corian s'adapte à toutes vos envies.",
    features: [
      "Personnalisation : Couleurs, formes, intégrations... un comptoir unique pour une cuisine unique.",
      "Hygiène : Surface non poreuse, antibactérienne et facile à nettoyer.",
      "Esthétique : Un aspect soyeux et chaleureux, disponible dans une large palette de couleurs.",
      "Réparation : Les éventuelles rayures peuvent être facilement réparées.",
    ],
    image: require("../media/corian.jpg"),
  },
  {
    title: "Inox et Corian : le duo gagnant pour vos comptoirs",
    subtitle: "Inox et Corian : le meilleur des deux mondes",
    description:
      "Nous maîtrisons la fabrication de comptoirs en inox et en Corian. Selon vos besoins et vos préférences, nous vous proposons la solution la mieux adaptée.",
    features: [
      "Inox : Idéal pour les environnements exigeants en termes d'hygiène et de durabilité.",
      "Corian : Parfait pour créer des designs personnalisés et élégants.",
      "Combinaisons : Nous pouvons associer ces deux matériaux pour un rendu unique.",
    ],
    image: require("../media/entreprise.jpg"),
  },
]

export function HeroCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length)
    }, 40000)
    return () => clearInterval(timer)
  }, [])

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length)
  }

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)
  }
  const Navigate = useNavigate()

  return (
    <div
      style={{ marginTop: scrolling ? "10px" : "100px" , paddingRight:"0",  transition: "all 0.7s" }}
      className="relative h-screen w-full  overflow-hidden"
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentSlide}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="relative h-full w-full"
        >
          <motion.div
            className="absolute inset-0 "
            initial={{ scale: 1.3 }}
            animate={{ scale: 1 }}
            transition={{ duration: 10 }}
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
              backgroundSize:"cover" ,
              backgroundPosition:"center",
              backgroundRepeat:"no-repeat",
              
              
            }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70" />
          </motion.div>

          <div className="relative mx-auto h-full w-full px-4 py-10 sm:py-20">
            <div className="flex h-full flex-col items-center justify-center text-center">
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mb-2 text-sm sm:text-lg font-medium text-[#007bff] uppercase tracking-wider"
              >
                {slides[currentSlide].subtitle}
              </motion.p>

              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="mb-4 sm:mb-6 max-w-4xl text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mx-auto leading-tight"
              >
                {slides[currentSlide].title}
              </motion.h1>

              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="mb-6 sm:mb-8 max-w-2xl text-base sm:text-lg text-gray-300 mx-auto"
              >
                {slides[currentSlide].description}
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="mb-6 sm:mb-8 grid gap-3 sm:gap-4 grid-cols-1 sm:grid-cols-2 w-full max-w-4xl mx-auto"
              >
                {slides[currentSlide].features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.8 + index * 0.1 }}
                    className="flex items-center space-x-2 bg-white/10 rounded-lg p-2 sm:p-3 backdrop-blur-sm"
                  >
                    <Check className="h-4 w-4 sm:h-5 sm:w-5 flex-shrink-0 text-[#007bff]" />
                    <span className="text-xs sm:text-sm text-white">{feature}</span>
                  </motion.div>
                ))}
              </motion.div>

              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1 }}
                className="rounded-full bg-[#007bff] px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold text-white transition-all hover:bg-[#0056b3] hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#007bff] focus:ring-offset-2 focus:ring-offset-gray-900"
                onClick={() => Navigate("/contact")}
              >
                Demandez votre devis gratuit
              </motion.button>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Navigation Arrows */}
      <button
        onClick={prevSlide}
        className="absolute left-2  top-1/2 -translate-y-1/2 rounded-full bg-white/10 p-2 sm:p-3 text-white transition-all hover:bg-white/30 backdrop-blur-sm"
      >
        <ChevronLeft className="h-6 w-6 sm:h-8 sm:w-8" />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 rounded-full bg-white/10 p-2 sm:p-3 text-white transition-all hover:bg-white/30 backdrop-blur-sm"
      >
        <ChevronRight className="h-6 w-6 sm:h-8 sm:w-8" />
      </button>

      {/* Slide Indicators */}
      <div className="absolute bottom-4 sm:bottom-8 left-1/2 flex -translate-x-1/2 space-x-2 sm:space-x-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`h-2 sm:h-3 rounded-full transition-all ${
              currentSlide === index ? "bg-[#007bff] w-8 sm:w-12" : "bg-white/50 w-2 sm:w-3 hover:bg-white/80"
            }`}
          />
        ))}
      </div>
    </div>
  )
}

