"use client"

import { motion } from "framer-motion"
import { Shield, Ruler, UserCheck, ThumbsUp } from "lucide-react"

const features = [
  {
    icon: Shield,
    title: "Qualité",
    description:
      "Matériaux de haute qualité et techniques de fabrication artisanale pour un produit durable et esthétique.",
  },
  {
    icon: Ruler,
    title: "Sur mesure",
    description: "Collaboration étroite pour créer un comptoir unique, adapté à vos besoins et à votre style.",
  },
  {
    icon: UserCheck,
    title: "Professionnalisme",
    description: "Équipes de professionnels qualifiés et expérimentés vous accompagnant tout au long de votre projet.",
  },
  {
    icon: ThumbsUp,
    title: "Satisfaction client",
    description: "Votre satisfaction est notre priorité. Service de qualité et produit répondant à vos attentes.",
  },
]

export function FeaturesSection() {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-zinc-900">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-white">
            Nos <span className="text-[#007bff]">Engagements</span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Découvrez ce qui fait de Dashiki Comptoir votre partenaire de confiance pour des comptoirs d'exception.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-zinc-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 group"
            >
              <div className="mb-4 p-3 bg-[#007bff] rounded-full w-16 h-16 flex items-center justify-center mx-auto group-hover:bg-white transition-colors duration-300">
                <feature.icon className="w-8 h-8 text-white group-hover:text-[#007bff]" />
              </div>
              <h3 className="text-xl text-center font-semibold mb-2 text-white group-hover:text-[#007bff] transition-colors duration-300">
                {feature.title}
              </h3>
              <p className="text-gray-300 text-center">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-16 text-center"
        >
          <a
            href="/contact"
            className="inline-block bg-[#007bff] text-white px-8 py-3 rounded-full font-semibold hover:bg-white hover:text-[#007bff] transition-colors duration-300 shadow-lg hover:shadow-xl"
          >
            Découvrez Notre Expertise
          </a>
        </motion.div>
      </div>
    </section>
  )
}

