"use client"

import { useState } from "react"
import { motion } from "framer-motion"
import { Phone, Mail, MapPin, Send, PhoneCall, Loader2 } from "lucide-react"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    project: "",
    message: "",
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(null)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setSubmitStatus(null)

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: "1c3dfc5a-dd0c-4c7f-a752-f1563ae2ed62",
          ...formData,
        }),
      })

      const result = await response.json()
      if (result.success) {
        setSubmitStatus("success")
        setFormData({ name: "", phone: "", email: "", project: "", message: "" })
      } else {
        setSubmitStatus("error")
      }
    } catch (error) {
      setSubmitStatus("error")
    }

    setIsSubmitting(false)
  }

  const inputVariants = {
    focus: { scale: 1.02, transition: { type: "spring", stiffness: 300 } },
  }

  const formSectionVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.2 } },
  }

  const infoSectionVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5, delay: 0.4 } },
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-800    py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.h1
          className="text-5xl mt-20 font-bold text-center text-white mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Contactez-nous
        </motion.h1>

        <motion.p
          className="text-center text-gray-200 mb-12 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          N'hésitez pas à nous contacter pour toute demande de devis ou information complémentaire. Nous serons ravis de
          vous conseiller et de vous accompagner dans votre projet de comptoir en inox ou en Corian.
        </motion.p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <motion.div
            className="bg-white shadow-2xl rounded-lg p-8"
            variants={formSectionVariants}
            initial="hidden"
            animate="visible"
          >
            <h2 className="text-3xl font-semibold mb-6 text-center text-gray-900">Formulaire de contact</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <motion.div className="relative" variants={inputVariants} whileFocus="focus">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border-b-2 border-gray-300 focus:border-[#007bff] focus:outline-none transition-all duration-300 peer placeholder-transparent"
                    placeholder="Nom"
                    required
                  />
                  <label
                    htmlFor="name"
                    className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-[#007bff]"
                  >
                    Nom
                  </label>
                </motion.div>
                <motion.div className="relative" variants={inputVariants} whileFocus="focus">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border-b-2 border-gray-300 focus:border-[#007bff] focus:outline-none transition-all duration-300 peer placeholder-transparent"
                    placeholder="Téléphone"
                    required
                  />
                  <label
                    htmlFor="phone"
                    className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-[#007bff]"
                  >
                    Téléphone
                  </label>
                </motion.div>
              </div>
              {["email", "project"].map((field) => (
                <motion.div key={field} className="relative" variants={inputVariants} whileFocus="focus">
                  <input
                    type={field === "email" ? "email" : "text"}
                    name={field}
                    id={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border-b-2 border-gray-300 focus:border-[#007bff] focus:outline-none transition-all duration-300 peer placeholder-transparent"
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    required
                  />
                  <label
                    htmlFor={field}
                    className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-[#007bff]"
                  >
                    {field === "email" ? "Email" : "Projet"}
                  </label>
                </motion.div>
              ))}
              <motion.div className="relative" variants={inputVariants} whileFocus="focus">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border-b-2 border-gray-300 focus:border-[#007bff] focus:outline-none transition-all duration-300 peer placeholder-transparent resize-none"
                  placeholder="Message"
                  required
                ></textarea>
                <label
                  htmlFor="message"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-[#007bff]"
                >
                  Message
                </label>
              </motion.div>
              <motion.button
                type="submit"
                className="w-full bg-[#007bff] text-white py-3 px-6 rounded-full hover:bg-[#0056b3] transition-colors duration-300 flex items-center justify-center text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Loader2 className="mr-2 h-5 w-5 animate-spin" /> : <Send className="mr-2" size={20} />}
                {isSubmitting ? "Envoi en cours..." : "Envoyer"}
              </motion.button>
              {submitStatus === "success" && (
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-green-600 text-center mt-4"
                >
                  Votre message a été envoyé avec succès !
                </motion.p>
              )}
              {submitStatus === "error" && (
                <motion.p
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-600 text-center mt-4"
                >
                  Une erreur s'est produite. Veuillez réessayer.
                </motion.p>
              )}
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div
            className="bg-white shadow-2xl rounded-lg p-8"
            variants={infoSectionVariants}
            initial="hidden"
            animate="visible"
          >
            <h2 className="text-3xl font-semibold mb-6 text-center text-gray-900">Informations de contact</h2>
            <div className="space-y-6">
              <motion.div
                className="flex items-center"
                whileHover={{ x: 10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Phone className="text-[#007bff] mr-4" size={24} />
                <p className="text-lg">+216 27 781 212</p>
              </motion.div>
              <motion.div
                className="flex items-center"
                whileHover={{ x: 10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Mail className="text-[#007bff] mr-4" size={24} />
                <p className="text-lg">dashikicomptoirdesign@gmail.com</p>
              </motion.div>
              <motion.div
                className="flex items-center"
                whileHover={{ x: 10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <MapPin className="text-[#007bff] mr-4" size={24} />
                <p className="text-lg">Marsa, Tunis</p>
              </motion.div>
            </div>

            <div className="mt-12">
              <h3 className="text-2xl font-semibold mb-4 text-gray-800">Notre localisation</h3>
              <motion.div
                className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d843939.5102907158!2d10.4073968491861!3d34.28326402927714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6310ed787d865bc3%3A0xc24df15eab0b6167!2sDashiki%20Comptoir%20Design!5e0!3m2!1sar!2stn!4v1739955090349!5m2!1sar!2stn"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </motion.div>
            </div>

            <motion.button
              className="mt-12 w-full bg-[#007bff] text-white py-3 px-6 rounded-full hover:bg-[#0056b3] transition-colors duration-300 flex items-center justify-center text-lg font-semibold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PhoneCall className="mr-2" size={20} />
              Demander une consultation
            </motion.button>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Contact

